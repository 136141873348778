import type { ILaboratoryOpenHours } from '@/types/types'

export default function openingHours(openingHours: ILaboratoryOpenHours) {
  if (!Object.keys(openingHours).length || typeof openingHours !== 'object')
    return ''

  const dayNames: any = {
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    sunday: 'Dom',
  }

  const sortedOpeningHours: any = {}

  for (const day in dayNames) {
    const newDayName = dayNames[day]
    sortedOpeningHours[newDayName] = openingHours[day]
  }

  const hoursToDaysMap: any = {}

  for (const [day, hours] of Object.entries(sortedOpeningHours) as [string, { start: string, end: string }[]][]) {
    if (!hours.length)
      continue

    const hoursString = JSON.stringify(hours)

    if (!hoursToDaysMap[hoursString])
      hoursToDaysMap[hoursString] = []

    hoursToDaysMap[hoursString].push(day)
  }

  return Object.keys(hoursToDaysMap)
    .map((key) => {
      const initialDay = hoursToDaysMap[key][0]
      let lastDay = ''
      if (hoursToDaysMap[key].length > 1)
        lastDay = ` - ${hoursToDaysMap[key][hoursToDaysMap[key].length - 1]}`

      const [start, end] = Object.values(JSON.parse(key)[0])

      return `${initialDay}${lastDay} • ${start} - ${end}`
    })
    .join(' ')
}
