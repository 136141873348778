import type { IAddressFilter } from '@/types/types'

/**
 * Formata um objeto de endereço em uma string padronizada.
 *
 * @param {IAddressFilter} params - Objeto de endereço com as seguintes chaves:
 * @param {string} params.address - Nome da rua ou logradouro
 * @param {string} params.district - Bairro
 * @param {string} params.number - Número do endereço
 * @param {string} params.city - Nome da cidade (opcional)
 * @returns {string} Retorna uma string formatada do endereço no formato:
 *   - Com cidade: "logradouro, número - bairro, cidade"
 *   - Sem cidade: "logradouro, número - bairro"
 *   - Retorna "--" se algum campo obrigatório estiver faltando
 */
export function formatAddress({ address, district, number, city }: IAddressFilter): string {
  const DEFAULT_ADDRESS = '--'

  if (!address || !district || !number) {
    return DEFAULT_ADDRESS
  }

  const baseAddress = `${address}, ${number} - ${district}`

  if (!city) {
    return baseAddress
  }

  return `${baseAddress}, ${city}`
}
